
.landingPage{
    background-color: rgb(234, 234, 234);
    position: absolute;
    top: 81px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 120%;
    }

.banner {
    background-image: url('../images/gradient.jpg');
    background-position: inherit;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: center;
}

body {
    background-color: rgb(225, 225, 225);
}

.wrapper {
    margin-top: 80px;
}

/* NavBar */

.navUsername {
    position: absolute;
    right: 2%;
}

.navBar {
    background-color: white;
    border-bottom: 1px rgb(175, 171, 171) solid;
    width: 100%;
    height: 5em;
    z-index: 10;   
    position: fixed;
}

.loggedIn {
    position: fixed;
}

.logo {
    height: 40px;
    width: auto;
    margin: 0.5em 2.5em ;
    display: inline;
    float: left;
}

.navbuttons {
    display: block;
    position: relative;
    float: right;
    margin: 20px 0;
}

.loginButton{
    background-color: #EAEAEA;
    border-radius: 0.25em;
    border-style: hidden;
    color: #1682FD;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    width: 80px;
    margin-left: 8px;
    margin-right: 30px;
}

.loginButton:active {
    background-color: 309A47;
    box-shadow: 0 2px gray;
    transform: translateY(4px);
    border-radius: 0.25em;
  }


/* Landing Page */

.buttonGreenSmall{
    padding: 10px;
    border-style: hidden;
    width: 90px;
    background-color: #309A47;
    color: white;
    display: inline;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    border-radius: 0.25em;
    margin-right: 8px;
}

.buttonGreenSmall:active {
    background-color: 309A47;
    box-shadow: 0 2px gray;
    transform: translateY(4px);
    border-radius: 0.25em;
  }

.buttonGreenBig {
    display: inline-block;
    padding: 12px 60px;
    margin: 15px;

    width: 500px;
    height: 250px;

    background: #309A47;
    border-radius: 8px;
    border: none;

    text-align: center;
}

.buttonGreenBig:active {
    background-color: 309A47;
    box-shadow: 0 2px gray;
    transform: translateY(4px);
    border-radius: 0.25em;
  }

.bigLogo {
    display: block;
    width: auto;
    height: 150px;
    margin: 5px auto;

}

.bannerArea {
    display: block;
    margin: 0 auto;
    text-align: center;
    
}

.bigBlock {
    display: block;
    margin: 0 auto;
    padding: 0 auto;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    border: none;
    width: 55%;
    padding-bottom: 20px;
}

.adopter {
    float: left;
    margin-top: 50px;
    margin-bottom: 20px;
}

.adopter, .innovator {
    text-align: center;
    width: 50%;
    padding: 0 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
}

.innovator {
    float: right;
    margin-top: 50px;
    margin-bottom: 20px;
}

.landingPageButtons {
    display: block;
    text-align: center;
    width: max-content;
    margin: 0 auto;
}

.landingPageButtons .nav-link {
    display: block;
    width: auto;
    float: left;
    z-index: 1;
}

.landingFont {
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
}

.getStarted .landingButton {
    display: inline-block;
    padding: 12px 20px;
    margin: 0 auto;
    gap: 12px;

    width: auto;
    height: 47px;

    background: #309A47;
    border-radius: 5px;
    color: white;
    border: none;
}

.getStarted .landingButton:active {
    background-color: 309A47;
    box-shadow: 0 2px gray;
    transform: translateY(4px);
    border-radius: 0.25em;
  }

.getStarted {
    display: block;
    width: fit-content;
    margin: 0 auto;
}

/* Side Panel */

.panel {
    margin: 80px 5px 0 0;
    padding: 3em;
    float: left;
    display: block;
    height: 100%;
    width: fit-content;
    position: fixed;
    background-color: white;
    z-index: 10;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 50px;
}

.menuButton {
    color: rgb(67, 182, 76);
}

