body{
margin: 0;
height: 100%;
} 

.edit-user{
  background-image: url("../images/tree.jpg");
  background-repeat: no-repeat;
    background-size: 100%;
    background-position: fixed;
    position: absolute;
    padding: 120px 350px;
    top: 60px;
    right: 0px;
    bottom: 0px;
    left: 50px;
    width: 100%;
    height: 100%;
    margin-left: 70px;
  }
  

.user-question{
  background-image: url("../images/field.jpg");
  background-repeat: no-repeat;
    background-size: 100%;
    background-position: fixed;
    position: absolute;
    top: 80px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

.company-creation-background{
background-image: url("../images/clean.jpg");
background-repeat: no-repeat;
background-size: 150%;
background-position: fixed;
position: absolute;
top: 67px;
right: 0px;
bottom: 0px;
left: 0px;
width: 100%;
height: 140%;
}

.user-creation-background{
  background-image: url("../images/user-registration.jpg");
  background-repeat: no-repeat;
    background-size: 100%;
    background-position: fixed;
    position: absolute;
    top: 80px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 96%;
  }

  .user-login-background{
    background-image: url("../images/user-login.jpg");
    background-repeat: no-repeat;
      background-size: 100%;
      background-position: fixed;
      position: absolute;
      top: 80px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 96%;
    }


main{
  color: #40514E;
  margin: 0;
  text-align: center;
  /* padding: 50px 0; */
  font-family: 'Roboto', serif;
  background-position: fixed;
}

.border{
  margin-top: 100px;
  width: 500px;
  padding: 10px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.8em;
  background-color: rgba(255,255,255,.80);
  
}

.border_two{
  margin-top: 50px;
  width: 620px;
  padding: 10px 10px 20px 10px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.8em;
  background-color: rgba(255,255,255,.80);
  
}


h2{
  color: black;
  font-family: 'Roboto', sans-serif;
  /* font-weight: normal; */
  padding-bottom: 10px;
  font-weight: bolder;
  margin-left: 35;
}

.first-header{
  padding-top: 30px;
  font-weight: bolder;
  font-size: 17px;
}

.company-creation-description{
  font-size: 15px;
  font-weight: bolder;
}

.second-header{
  font-weight: bolder;
  font-size: 17px;
  text-align: center;
  padding: 30px 295px 0 0;
}

textarea{
  line-height: 1;
}


a{
  color: #11999E;
  font-family: 'Roboto', sans-serif;
  /* margin: 10px 20px; */
  text-decoration: none;
} 

a:hover{
  color: #1682FD;
}

.form-group-create {
  font-size: 15px;
  font-weight: bold;
  margin-left: -55px;
  width: 350px;
}

.form-group-create-select {
  font-size: 15px;
  font-weight: bold;
  margin-left: -55px;
  width: 350px;
}


.form-check{
  padding: 15px 85px 0 75px;
  font-size: 15px;
  font-weight: bold;
  margin-left: 0px;
  width: 700px;
}

.form-group-login {
  font-size: 15px;
  font-weight: bold;
  margin-left: -75px;
  width: 650px;
}

.form-group-login input{
  margin: auto;
  width: 38.5%;

}

.form-group-login select{
  width: 40%;

}

.form-group-register {
  font-size: 15px;
  font-weight: bold;
  margin-left: -75px;
  width: 650px;
}

.form-group-register input{
  margin: auto;
  width: 38.5%;

}

.form-group-register select{
  width: 40%;

}

label {
  /* Other styling... */
  font-size: 15px;
  font-weight: bold;
  text-align: right;
  clear: both;
  margin-left: 20px;
  margin-right:50px;
}

#companyName{
  
  text-align: left;
  margin-left: 100px;
  margin-right: 50px; 
  width: 300px;
  display: inline-block;
  padding: 6px; 
}


#type{
  text-align: left;
margin-left: 44px;
 margin-right: 25px;
 width: 300px;
 display: inline-block;
 padding: 6px;
}



#file{
text-align: left;
 margin-left: 38px;
 margin-right: 55px;
 width: 300px;
 display: inline-block;
 padding: 6px;
} 

#sector{
  text-align: left;
 margin-left: 98px;
 margin-right: 50px;
 width: 300px;
 display: inline-block;
 padding: 6px;
}
/* 
label.level{
  margin-left: -265px;
} */

#level{
  text-align: left;
  margin-left: -46px;
  margin-right: 25px;
 width: 300px;
 display: inline-block;
 padding: 6px;
}

#employees{
  text-align: left;
  margin-left: -6px;
 margin-right: 50px;
 width: 300px;
 display: inline-block;
 padding: 6px;
}

#year{
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
  width: 300px;
 display: inline-block;
 padding: 6px;
}

#website{
  text-align: left;
  margin-left: 86px;
  margin-right: 48px;
  width: 300px;
 display: inline-block;
 padding: 6px;
}

#address{
  text-align: left;
  margin-left: 86px;
  margin-right: 50px;
  width: 300px;
 display: inline-block;
 padding: 6px;
}

#city{
  text-align: left;
  margin-left: 113px;
  margin-right: 48px;
  width: 300px;
 display: inline-block;
 padding: 6px;
}

#zip{
  text-align: left;
  margin-left: 116px;
  margin-right: 50px;
  width: 300px;
 display: inline-block;
 padding: 6px;
}

/*Country*/
#formControlSelect7{ 
  text-align: left;
  margin-left: 86px;
  margin-right: 52px;
  width: 300px;
 display: inline-block;
 padding: 6px;
}

/*Province-State*/
#formControlSelect4{
  text-align: left;
  margin-left: 40px;
  margin-right: 52px;
  width: 300px;
 display: inline-block;
 padding: 6px;
}




#usernameID{
  text-align: left;
  margin-left: 60px;
  margin-right: 80px;
  width: 200px;
  display: inline-block;
  padding: 6px;
}

#emailID{
  text-align: left;
  margin-left: 90px;
  margin-right: 80px;
  width: 200px;
  display: inline-block;
  padding: 6px;
} 

#passwordID{
  text-align: left;
  margin-left: 60px;
  margin-right: 80px;
  width: 200px;
  display: inline-block;
  padding: 6px;
}

#confirmPasswordID{
  text-align: left;
  margin-left: 6px;
  margin-right: 80px;
  width: 200px;
  clear: both;
  display: inline-block;
  padding: 6px;
} 

#email{
  text-align: left;
  margin-left: 50px;
  margin-right: 80px;
  width: 200px;
  display: inline-block;
  padding: 6px;
}

#password{
  text-align: left;
  margin-left: 20px;
  margin-right: 79px;
  width: 200px;
  display: inline-block;
  padding: 6px;
} 

#defaultCheck1{
  margin-left: 20px;
  width: 15px;
  height: 15px;
}

label.disclaimer{
  font-size: 11px;
  padding: 1px;
  margin-left: -65px;
  width: 380px;
  text-align: left;

}

p{

}

div{

} 


.form-check-input{
  font-size: 12px;
}

.disclaimer{
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0 10px 30px;
  width: 400px;
  font-size: 12px;
  font-weight: bold;
}

.terms{
  padding: 0 120px 0 110px;
  text-align: center;
  font-size: 12px;
}

.createButton{
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 10px 25px;
    border-style: hidden;
    width: 240px;
    background-color: gray;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 0.25em;
}

.createButton:active {
  background-color: #309A47;
  box-shadow: 0 2px #666;
  transform: translateY(4px);
  border-radius: 0.25em;
}
.createButton:hover{
  background-color: #309A47;
}

.createButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
} 

.createButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.createButton:hover span {
  padding-right: 25px;
}

.createButton:hover span:after {
  opacity: 1;
  right: 0;
}

.logButton{
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 10px 25px;
    border-style: hidden;
    width: 150px;
    background-color: gray;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 0.25em;
}

.logButton:active {
  background-color: #309A47;
  box-shadow: 0 2px #666;
  transform: translateY(4px);
  border-radius: 0.25em;
}
.logButton:hover{
  background-color: #309A47;
}

.logButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.logButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.logButton:hover span {
  padding-right: 25px;
}

.logButton:hover span:after {
  opacity: 1;
  right: 0;
}

.registerButton{
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 10px 25px;
    border-style: hidden;
    width: 150px;
    background-color: gray;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 0.25em;
}

.registerButton:active {
  background-color: #309A47;
  box-shadow: 0 2px #666;
  transform: translateY(4px);
  border-radius: 0.25em;
}
.registerButton:hover{
  background-color: #309A47;
}

.registerButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.registerButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.registerButtonn:hover span {
  padding-right: 25px;
}

.registerButton:hover span:after {
  opacity: 1;
  right: 0;
}

.login_links{
  font-size: 12px;
}

